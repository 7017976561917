import { Link } from 'react-router-dom';
import './home.css';

const Component = () => {

    return(<>
         <div className="container-fluid banner">
        <div className="row">
       <div className="col-sm-1"></div>
          <div className="col-sm-7">
              
              <h1>
                  <b>
                    WE OFFER ADVANCED PRACTICAL TRAINING IN FULL STACK WEB, APPLICATION 
                    AND SOFTWARE DEVELOPMENT  
                  </b>
                </h1>
                <Link to="/Training-Brochure.pdf" target="_blank">
                <button className="btn"><span className="pi pi-download"></span>&nbsp; Training Brochure</button>
                </Link>
          </div>
          
       
          <div className="col-sm-2"></div>

          <div class="col-sm-2" id="banner_right">
                    
                    <div class="row right_list">
                        <ul>
                            <li>C, C++ & DSA</li>
                        </ul>    
                    </div>
                    
                    <div class="row right_list2">
                        <ul>
                            <li>Web Frontend</li>
                        </ul>    
                    </div><br />
                    
                    <div class="row right_list3">
                        <ul>
                            <li>PHP & MYSQL</li>
                        </ul>    
                    </div>
                    
                 
                    <div class="row right_list3">
                        <ul>
                            <li>MERN STACK</li>
                        </ul>    
                    </div>
                    
                    <div class="row right_list3">
                        <ul>
                            <li>React Native</li>
                        </ul>    
                    </div>
                    
                    
                </div>
                
        </div>
        <br /> <br />
    </div>

   
    
    </>)
}

export default Component;