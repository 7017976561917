import { useState, useEffect } from 'react';

import Course from './Course.js';
import { url, userAuthorization } from './../../../lib/lib.js';

import './courses.css';

const Component = () => {

    const [data, setData] = useState([]);

    function fetchData(){

        fetch(url+'/course/list', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'authorization': userAuthorization,
                'type': 'user'
            }
        })
        .then((res)=>res.json())
        .then((data)=>{
            setData(data.Data);
        })
    }

    function jsonData(){

        let data = {
            "Status": true,
            "Data": [
              {
                "_id": "652062c4c626f3f0b287a68f",
                "sequence": 1,
                "category_id": {
                  "_id": "65188020d6a48b7af574fb62",
                  "sequence": 1,
                  "name": "Coding",
                  "__v": 0,
                  "createdAt": "2023-09-30T20:08:00.316Z",
                  "updatedAt": "2023-09-30T20:08:00.316Z"
                },
                "name": "Full Stack Web & Software Development With MERN",
                "mrp": "60,000",
                "price": "30,000",
                "duration": "4 - 6 Months",
                "technology": [
                  {
                    "sequence": 1,
                    "technology_id": {
                      "_id": "65134f474e384f245d26d9ce",
                      "name": "HTML",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "Introduction",
                          "_id": "65134f474e384f245d26d9cf"
                        },
                        {
                          "sequence": 2,
                          "name": "Variables",
                          "_id": "65134f474e384f245d26d9d0"
                        }
                      ],
                      "createdAt": "2023-09-26T21:38:15.765Z",
                      "updatedAt": "2023-10-09T19:12:32.283Z",
                      "__v": 0,
                      "category_id": "65188020d6a48b7af574fb62",
                      "sequence": 1
                    },
                    "_id": "652062c4c626f3f0b287a690"
                  },
                  {
                    "sequence": 2,
                    "technology_id": {
                      "_id": "65134f4b4e384f245d26d9d2",
                      "name": "CSS",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "Introduction",
                          "_id": "65134f4b4e384f245d26d9d3"
                        },
                        {
                          "sequence": 2,
                          "name": "Variables",
                          "_id": "65134f4b4e384f245d26d9d4"
                        }
                      ],
                      "createdAt": "2023-09-26T21:38:19.849Z",
                      "updatedAt": "2023-10-09T19:12:37.794Z",
                      "__v": 0,
                      "category_id": "65188020d6a48b7af574fb62",
                      "sequence": 2
                    },
                    "_id": "652062c4c626f3f0b287a691"
                  },
                  {
                    "sequence": 3,
                    "technology_id": {
                      "_id": "6519e159641cc51a35a6f3b5",
                      "sequence": 3,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "Bootstrap",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "Introduction",
                          "_id": "6519e159641cc51a35a6f3b6"
                        },
                        {
                          "sequence": 2,
                          "name": "Gnn",
                          "_id": "6519e159641cc51a35a6f3b7"
                        },
                        {
                          "sequence": 3,
                          "name": "Vguh",
                          "_id": "6519e159641cc51a35a6f3b8"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-01T21:15:05.706Z",
                      "updatedAt": "2023-10-09T19:12:43.842Z"
                    },
                    "_id": "652062c4c626f3f0b287a692"
                  },
                  {
                    "sequence": 4,
                    "technology_id": {
                      "_id": "65205ec40573cc38a4d16a9e",
                      "sequence": 4,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "JavaScript",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "q",
                          "_id": "65205ec40573cc38a4d16a9f"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:23:48.100Z",
                      "updatedAt": "2023-10-09T19:12:52.362Z"
                    },
                    "_id": "652062c4c626f3f0b287a693"
                  },
                  {
                    "sequence": 5,
                    "technology_id": {
                      "_id": "652060d1c626f3f0b287a37b",
                      "sequence": 5,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "JQuery",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "a",
                          "_id": "652060d1c626f3f0b287a37c"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:32:33.343Z",
                      "updatedAt": "2023-10-09T19:12:57.137Z"
                    },
                    "_id": "652062c4c626f3f0b287a694"
                  },
                  {
                    "sequence": 6,
                    "technology_id": {
                      "_id": "652060dec626f3f0b287a38e",
                      "sequence": 6,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "React JS",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "1",
                          "_id": "652060dec626f3f0b287a38f"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:32:46.078Z",
                      "updatedAt": "2023-10-09T19:13:01.731Z"
                    },
                    "_id": "652062c4c626f3f0b287a695"
                  },
                  {
                    "sequence": 7,
                    "technology_id": {
                      "_id": "652060e7c626f3f0b287a3a2",
                      "sequence": 7,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "Node JS",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "1",
                          "_id": "652060e7c626f3f0b287a3a3"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:32:55.911Z",
                      "updatedAt": "2023-10-09T19:13:06.097Z"
                    },
                    "_id": "652062c4c626f3f0b287a696"
                  },
                  {
                    "sequence": 8,
                    "technology_id": {
                      "_id": "652060f1c626f3f0b287a3b7",
                      "sequence": 8,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "Express JS",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "1",
                          "_id": "652060f1c626f3f0b287a3b8"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:33:05.746Z",
                      "updatedAt": "2023-10-09T19:13:12.206Z"
                    },
                    "_id": "652062c4c626f3f0b287a697"
                  },
                  {
                    "sequence": 9,
                    "technology_id": {
                      "_id": "652060ffc626f3f0b287a3cd",
                      "sequence": 9,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "MongoDB",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "1",
                          "_id": "652060ffc626f3f0b287a3ce"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:33:19.501Z",
                      "updatedAt": "2023-10-09T19:13:16.937Z"
                    },
                    "_id": "652062c4c626f3f0b287a698"
                  }
                ],
                "__v": 0,
                "createdAt": "2023-10-06T19:40:52.113Z",
                "updatedAt": "2023-10-06T21:00:10.518Z"
              },
              {
                "_id": "65206508c626f3f0b287aade",
                "sequence": 5,
                "category_id": {
                  "_id": "65188020d6a48b7af574fb62",
                  "sequence": 1,
                  "name": "Coding",
                  "__v": 0,
                  "createdAt": "2023-09-30T20:08:00.316Z",
                  "updatedAt": "2023-09-30T20:08:00.316Z"
                },
                "name": "Website Frontend Designing (With React JS)",
                "mrp": "30,000",
                "price": "15,000",
                "duration": "3 - 4 Months",
                "technology": [
                  {
                    "sequence": 1,
                    "technology_id": {
                      "_id": "65134f474e384f245d26d9ce",
                      "name": "HTML",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "Introduction",
                          "_id": "65134f474e384f245d26d9cf"
                        },
                        {
                          "sequence": 2,
                          "name": "Variables",
                          "_id": "65134f474e384f245d26d9d0"
                        }
                      ],
                      "createdAt": "2023-09-26T21:38:15.765Z",
                      "updatedAt": "2023-10-09T19:12:32.283Z",
                      "__v": 0,
                      "category_id": "65188020d6a48b7af574fb62",
                      "sequence": 1
                    },
                    "_id": "65206508c626f3f0b287aadf"
                  },
                  {
                    "sequence": 2,
                    "technology_id": {
                      "_id": "65134f4b4e384f245d26d9d2",
                      "name": "CSS",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "Introduction",
                          "_id": "65134f4b4e384f245d26d9d3"
                        },
                        {
                          "sequence": 2,
                          "name": "Variables",
                          "_id": "65134f4b4e384f245d26d9d4"
                        }
                      ],
                      "createdAt": "2023-09-26T21:38:19.849Z",
                      "updatedAt": "2023-10-09T19:12:37.794Z",
                      "__v": 0,
                      "category_id": "65188020d6a48b7af574fb62",
                      "sequence": 2
                    },
                    "_id": "65206508c626f3f0b287aae0"
                  },
                  {
                    "sequence": 3,
                    "technology_id": {
                      "_id": "6519e159641cc51a35a6f3b5",
                      "sequence": 3,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "Bootstrap",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "Introduction",
                          "_id": "6519e159641cc51a35a6f3b6"
                        },
                        {
                          "sequence": 2,
                          "name": "Gnn",
                          "_id": "6519e159641cc51a35a6f3b7"
                        },
                        {
                          "sequence": 3,
                          "name": "Vguh",
                          "_id": "6519e159641cc51a35a6f3b8"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-01T21:15:05.706Z",
                      "updatedAt": "2023-10-09T19:12:43.842Z"
                    },
                    "_id": "65206508c626f3f0b287aae1"
                  },
                  {
                    "sequence": 4,
                    "technology_id": {
                      "_id": "65205ec40573cc38a4d16a9e",
                      "sequence": 4,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "JavaScript",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "q",
                          "_id": "65205ec40573cc38a4d16a9f"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:23:48.100Z",
                      "updatedAt": "2023-10-09T19:12:52.362Z"
                    },
                    "_id": "65206508c626f3f0b287aae2"
                  },
                  {
                    "sequence": 5,
                    "technology_id": {
                      "_id": "652060d1c626f3f0b287a37b",
                      "sequence": 5,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "JQuery",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "a",
                          "_id": "652060d1c626f3f0b287a37c"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:32:33.343Z",
                      "updatedAt": "2023-10-09T19:12:57.137Z"
                    },
                    "_id": "65206508c626f3f0b287aae3"
                  },
                  {
                    "sequence": 6,
                    "technology_id": {
                      "_id": "652060dec626f3f0b287a38e",
                      "sequence": 6,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "React JS",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "1",
                          "_id": "652060dec626f3f0b287a38f"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:32:46.078Z",
                      "updatedAt": "2023-10-09T19:13:01.731Z"
                    },
                    "_id": "65206508c626f3f0b287aae4"
                  }
                ],
                "__v": 0,
                "createdAt": "2023-10-06T19:50:32.788Z",
                "updatedAt": "2023-10-08T12:58:53.622Z"
              },
              {
                "_id": "652070c3c626f3f0b287b23b",
                "sequence": 6,
                "category_id": {
                  "_id": "65188020d6a48b7af574fb62",
                  "sequence": 1,
                  "name": "Coding",
                  "__v": 0,
                  "createdAt": "2023-09-30T20:08:00.316Z",
                  "updatedAt": "2023-09-30T20:08:00.316Z"
                },
                "name": "Website Backend Development (With Node JS)",
                "mrp": "40,000",
                "price": "20,000",
                "duration": "2 - 4 Months",
                "technology": [
                  {
                    "sequence": 1,
                    "technology_id": {
                      "_id": "65205ec40573cc38a4d16a9e",
                      "sequence": 4,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "JavaScript",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "q",
                          "_id": "65205ec40573cc38a4d16a9f"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:23:48.100Z",
                      "updatedAt": "2023-10-09T19:12:52.362Z"
                    },
                    "_id": "652070c3c626f3f0b287b23c"
                  },
                  {
                    "sequence": 2,
                    "technology_id": {
                      "_id": "652060e7c626f3f0b287a3a2",
                      "sequence": 7,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "Node JS",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "1",
                          "_id": "652060e7c626f3f0b287a3a3"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:32:55.911Z",
                      "updatedAt": "2023-10-09T19:13:06.097Z"
                    },
                    "_id": "652070c3c626f3f0b287b23d"
                  },
                  {
                    "sequence": 3,
                    "technology_id": {
                      "_id": "652060f1c626f3f0b287a3b7",
                      "sequence": 8,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "Express JS",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "1",
                          "_id": "652060f1c626f3f0b287a3b8"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:33:05.746Z",
                      "updatedAt": "2023-10-09T19:13:12.206Z"
                    },
                    "_id": "652070c3c626f3f0b287b23e"
                  },
                  {
                    "sequence": 4,
                    "technology_id": {
                      "_id": "652060ffc626f3f0b287a3cd",
                      "sequence": 9,
                      "category_id": "65188020d6a48b7af574fb62",
                      "name": "MongoDB",
                      "module": [
                        {
                          "sequence": 1,
                          "name": "1",
                          "_id": "652060ffc626f3f0b287a3ce"
                        }
                      ],
                      "__v": 0,
                      "createdAt": "2023-10-06T19:33:19.501Z",
                      "updatedAt": "2023-10-09T19:13:16.937Z"
                    },
                    "_id": "652070c3c626f3f0b287b23f"
                  }
                ],
                "__v": 0,
                "createdAt": "2023-10-06T20:40:35.435Z",
                "updatedAt": "2023-10-08T18:28:31.998Z"
              }
            ]
          }

        setData(data.Data);  
    }

    useEffect(()=>{
        jsonData();
    }, []);

    return(<>
        <div className="container-fluid training_courses">
       
       <h1>Courses We Offer</h1>
        <div className="col-sm-1" ></div>
  <div className="col-sm-10" >
    <div className="row">
       
        {data.map((data, index)=><Course key={index} data={data} />)}
        
   </div>
  </div>

  <div className="col-sm-1"></div>
  
   
</div>

</>)
}

export default Component;