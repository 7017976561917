import React from "react";
import { Link } from "react-router-dom";

const Component = (props) => {

    return(<>
        <div className="col-sm-4" >
           <div className="cards" >
               <div className="row" >
                 <div className="col-sm-4">
                   <img src="/img/full_stack.png" className="img-responsive logo-icon"/>
               </div>
               <div className="col-sm-8" >
                   <h2 className="h2head" >{props.data.name}</h2>
                   <span className="time">Duration : {props.data.duration}</span>
               </div>  
               </div>
               <hr/>
               <div className="row">
                   <div className="col-sm-12 tech">
                       <h4>Technologies</h4>
                      <div className="tech1" >
                       
                      {props.data.technology.map((data, index)=>{
                            return(
                                <React.Fragment key={index} >
                                <div className="courses" style={{display:"inline-block",marginTop:"5px"}}>{data.technology_id.name} </div>
                                <span style={{"marginLeft":"4px"}}></span>
                                </React.Fragment>
                            )
                       })}
                         
                      </div>
               </div>
           </div>
           <div className="row feature">
                   <div className="col-sm-12">
                       <h4>Features</h4>
                       
                       <p>
                           <i className="pi pi-check-circle"></i>
                           &nbsp;
                           Practical Training
                       </p>
                       <p>
                           <i className="pi pi-check-circle"></i>
                           &nbsp;
                           Access To Live Online & Offline Classes
                       </p>
                       <p>
                           <i className="pi pi-check-circle"></i>
                           &nbsp;
                           Includes Live Project & Job Assistance
                       </p>
                       <p>
                           <i className="pi pi-check-circle"></i>
                           &nbsp;
                           Certification
                       </p>
                   </div>
               </div> 
               <div className="row pricing">
                   <div className="col-xs-6">
                        <strike>Rs. {props.data.mrp} /-</strike> <br /> <b>Rs. {props.data.price} /-</b>
                   </div>
                   <div className="col-xs-6">
                       <Link to="tel:+918448332454"> <button className="view">Know More</button> </Link>
                   </div>
               </div>
               
       </div>
   </div>
    </>)
}

export default Component;