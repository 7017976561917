import Nav from './../components/nav/Nav.js';
import Home from './../components/home/Home.js';
import Footer from '../components/footer/Footer.js';
import Features from '../components/features/Features.js';
import Contact from '../components/contact/Contact.js';
import Courses from '../components/courses/Courses.js';

import './../css/style.css'

const Component = () => {

    return(<>

        <Nav />
        <Home />

        <Features />
        <Courses />
        <Contact />

        <Footer />
    </>)
}

export default Component;