import './features.css';

const Component = () => {
    
    return(<>

        <div className="container-fluid training_feature">
            <div className="row">
                <div className="col-sm-1"></div>
                <div className="col-sm-4" style={{marginTop:"0px"}}>
                    <h1>Key Features & Amenities</h1>
                    <h5>We provide technical training to assist you in realizing your goals. To make your goal a reality, 
                        put us on your bucket list. We have vast expertise in Web Development, Software Development and Mobile App Development training.
                        <br /><br />
                        Book a Free Demo Session Now.
                        </h5>
                </div>
                <div className="col-sm-6">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="key-feature">
                                <h4><b>Training & Certification</b></h4>
                                <p>Get advanced training and certification in Full Stack Web, Application and Software Development.</p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="key-feature">
                                <h4><b>Practicals & Implementation</b></h4>
                                <p>Get one-to-one mentorship on enhanced and custom designed live Websites, Mobile Applications and Softwares.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="key-feature">
                                <h4><b>Internship & Job Assistance</b></h4>
                                <p>Get premium assistance on Internships and Jobs based on your profile and prove your skills to the world.</p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="key-feature">
                                <h4><b>Online & Offline Live Classes</b></h4>
                                <p>Get Online Live Classes from confort of your home or anywhere and Offline Live Classes from our physical centers.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1"></div>
            </div>
        </div>
    </>)
}

export default Component;