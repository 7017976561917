import { Link } from 'react-router-dom';

import './nav.css';

const Component = () => {
    
    return(<>
              <nav className="navbar navbar-inverse navbar-fixed-top" id="navbar">
                <div className="navbar-header">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar" style={{backgroundColor:"black"}}>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>                        
                </button>
                <Link to="/" className="navbar-brand">
                <img src="/img/codeletters.png" className="img-responsive logo" />
                </Link>
                </div>
                <div className="collapse navbar-collapse" id="myNavbar">
                
                <ul className="nav navbar-nav navbar-right" id="nav">
                   
                    <li className="different"><Link to="tel:+918448332454" className="btn">Book Free Counseling Session</Link></li>
                </ul>    
                </div>
                </nav>
    </>)
}

export default Component;