import './contact.css';

const Component = () => {

    return(<>

    
    </>)
}

export default Component;