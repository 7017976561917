import { Link } from 'react-router-dom';

import './footer.css';

const Component = () => {
    
    return(<>

    <div className="container-fluid footer">
            
            <div className="row footer1">
                
                <div className="col-md-1"></div>
                
                <div className="col-md-3">
                    <center>
                        <div className="row">
                            <img src="/img/codeletters.png" className="img-responsive footer_logo" alt="TechnicalShikshak" />
                        </div>
                         </center> 
                        
                        <div className="row footer_row1">
                               
                                <div className="col-md-2 col-xs-2">
                                    <i className="pi pi-phone icons"></i>
                                </div>
                                
                                <div className="col-md-10 col-xs-10">
                                    <p className="contact_details">(+91) - 8448332454</p>
                                </div>
                                
                            </div>
                            
                            <div className="row contact_row2">
                               
                                <div className="col-md-2 col-xs-2">
                                    <i className="pi pi-send icons"></i>    
                                </div>
                                
                                <div className="col-md-10 col-xs-10">
                                    <p className="contact_details">hello@codeletters.in</p>
                                </div>
                                
                            </div>
                            
                            <div className="row contact_row2">
                               
                                <div className="col-md-2 col-xs-2">
                                    <i className="pi pi-map-marker icons"></i>
                                </div>
                                
                                <div className="col-md-10 col-xs-10">
                                    <p className="contact_details">AndWork, Plot No. 5B, Sector 15A Neelam Chowk Ajronda 
                                    Metro Near Crown Plaza, Faridabad, Haryana 121007</p>
                                </div>
                                
                            </div>
                        
                  
                </div>
                
                <div className="col-md-2"></div>
                
                <div className="col-md-6">
                    
                    <div className="row">

                        <div className="col-md-4 col-xs-6">
                            <p className="footer_heading">Trainings</p>
                            <p className="footer_content">HTML, CSS & Bootstrap</p>
                            <p className="footer_content1">JavaScript & JQuery</p>
                            <p className="footer_content1">React JS</p>
                            <p className="footer_content1">Node JS</p>
                            <p className="footer_content1">Express JS</p>
                            <p className="footer_content1">MonogoDB</p>
                        </div>
                        
                        <div className="col-md-4 col-xs-6">
                            <p className="footer_heading">Projects</p>
                            <p className="footer_content">E-commerce Store</p>
                            <p className="footer_content1">Social Media App</p>
                            <p className="footer_content1">Billing Software</p>
                            <p className="footer_content1">Music Player</p>
                            <p className="footer_content1">Chatting App</p>
                            <p className="footer_content1">Learning App</p>
                        </div>
                        
                         <div className="col-md-4 col-xs-6">
                            <p className="footer_heading">Certifications</p>
                            <p className="footer_content">Frontend Developer</p>
                            <p className="footer_content1">Backend Developer</p>
                            <p className="footer_content1">Full Stack Developer</p>
                            <p className="footer_content1">Software Developer</p>
                            <p className="footer_content1">UI / UX Developer</p>
                            <p className="footer_content1">Project Manager</p>
                        </div>
                        
                         
                        
                    </div>
                    
                </div>
                
            </div>
            <div className="row" style={{"backgroundColor":"#3B3A3A","padding":"20px"}}>
                
                <div className="col-md-1"></div>
                
                <div className="col-md-6">
                    <img src="https://encrobytes.com/img/logo-white.png" style={{"height":"4vh","marginLeft":"-2.2vh"}} alt="Encrobytes"/>
                    <h5 style={{"color":"white","margin-left":"-15px"}}>Copyright &#169; 2024 <Link to="https://encrobytes.com" target="_blank">Encrobytes</Link>. All Rights Reserved.</h5>
                </div>
                <div className="col-md-4 footer-right">
                    {/*
                      <Link to="https://www.instagram.com/encrobytes/" target="_blank"><i className="pi pi-instagram"></i></Link>
                    <Link to="https://www.linkedin.com/company/encrobytes/" target="_blank"><i className="pi pi-linkedin"></i></Link>
                    <Link to="https://www.youtube.com/channel/UCpLaRYL7UvRmqiRRDb1xqRw" target="_blank"><i className="pi pi-youtube"></i></Link>
                      <Link to="https://www.facebook.com/pages/category/Software-Company/Encrobytes-Technologies-369824740540092/" target="_blank"><i className="pi pi-facebook"></i></Link>
                    */}
                </div>
                
                <div className="col-md-1"></div>
                
            </div>
            
    </div>

    </>)
}

export default Component;